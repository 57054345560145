import React, { useEffect, useState } from "react";
import { NovelCard } from "components/NovelCard";
import { Loader } from "components/Loader";
import { useAuth } from "hooks";
import { NovelsDahboard } from ".";
export const NovelRender = () => {
  const { bearerToken } = useAuth();
  const [ cards, setCards ] = useState([])
  const [ isLoading, setIsLoading ] = useState(false)
  const [ selectedNovel, setSelectedNovel ] = useState(null)
  async function getNovels () {
    setIsLoading(true)
    try {
      fetch(`${ process.env.REACT_APP_BASE_URL}/get-bought-novels`, {
        headers: {
          'authorization': `Bearer ${ bearerToken }`
        }
      }).then(res => res.json()).then(res => {
        setCards(() => res.data.map(item => item))
        setIsLoading(false)
      }).catch(e => console.log(e)).finally(() => setIsLoading(false))
    } catch (error) {
      console.log(error)
    }
  }
  function handleWishClick () {
    console.log(':)')
  }
  useEffect(() => {
    if(isLoading) return
    getNovels()
  }, [])
  return (
    <div className="novels-page-container d-flex flex-wrap home">
      <div className="most-wanted">
          { isLoading ? <Loader />  : 
          <>
            {
             !selectedNovel ?   
                          <div className="novels-container">
                            {
                              cards.map((item, i) => {
                                return <NovelCard handleOpenPdf={ () => setSelectedNovel(item) } clickable key={ i } card={ item } handleWishClick={ handleWishClick } />
                              })
                            }
                          </div>
                          : 

              <NovelsDahboard novel={ selectedNovel } />
            }
          </>
          }
      </div>
    </div>
  );
};

import { useEffect, useRef } from 'react';

const AdComponent = ({ adSlot, adFormat = "auto", style = { display: 'block' } }) => {
    const adRef = useRef(null);

    useEffect(() => {
        try {
            if (window.adsbygoogle && adRef.current) {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            }
        } catch (e) {
            console.error("AdSense error", e);
        }
    }, []);

    return (
        <ins
            className="adsbygoogle"
            style={{
                display: 'flex',
                backgroundColor: 'white',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '150px',
            }}
            data-ad-client="ca-pub-2727999479857514"
            data-ad-slot={adSlot}
            data-ad-format={adFormat}
            data-full-width-responsive="true"
            ref={adRef}
        />
    );
};

export default AdComponent;

import i18next from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as StarIcon } from "../../assets/img/star.svg";
// import { ReactComponent as FaveIcon } from "../../assets/img/favorite-border.svg";
import { ReactComponent as UserCircleIcon } from "../../assets/img/user-circle.svg";
import { NovelsDahboard } from "components/Dashboard";
import { useAuth } from "hooks";
// import { AddToWish } from "shared";
import { RequestNovel } from "components/RequestNovel";
import { Link, useParams } from "react-router-dom";
import { Loader } from "components/Loader";
// import CartDialog from "components/CartDialog";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AdLinear from 'components/AdLinear';

export const Novel = () => {
  const navigate = useNavigate()
  const currentLang = i18next.language;
  const { t } = useTranslation();
  const params = useParams();
  const [novelDetails, setNovelDetails] = useState({
    id: "",
    title: "",
    description: "",
    author_name: "",
    images: [],
    price: 0,
    price_after_discount: 0,
    is_wishlist: false,
    is_requested: false,
    is_paid: false,
    ratings: [],
    average_rating: 0,
    ratings_count: 0,
    in_cart: false,
  });

  const [novelRating, setNovelRating] = useState(0);
  const [novelImages, setNovelImages] = useState([]);
  const [mainImage, setMainImage] = useState("");
  const [ratings, setRatings] = useState([]);
  const [showRequest, setShowRequest] = useState(false);
  const [showRequestAnimations, setShowRequestAnimations] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [ isFetching, setIsFetching ] = useState(false)
  // const [open, setOpen] = useState(false);
  const [ isAdding, setIsAdding ] = useState(false)
  const { bearerToken, setLoginShow } = useAuth();
  const [ selectedNovel, setSelectedNovels ] = useState(null)
  const handleImageClick = (image) => {
    setMainImage(image);
  };

  
  const handelRequestHide = () => {
    setShowRequestAnimations(true);
    setTimeout(() => {
      setShowRequest(false);
      setShowRequestAnimations(false);
    }, 300);
  };

  const handleAddToNovels = (data) => {
    if (!bearerToken) {
      alert(t("You must be logged in"));
      setLoginShow(true);
      return;
    }
    if(data?.is_paid === false) {
      boughtNovel()
    }
    if(!data) return
    setSelectedNovels({ ...data })
    // navigate(`/novels/${ data.id }?pdf=${ data.pdf }&page_number=${ data.page_number || 0 }&dir=${ data.direction }`)
  };
   async function boughtNovel() {
    const formData = new FormData()
    formData.append('novel_id', params.id)
    fetch(`${ process.env.REACT_APP_BASE_URL }/checkout`, {
      method: 'POST',
      headers: {
        lang: currentLang,
        'authorization': `Bearer ${bearerToken}`,
      },
      body: formData
    }).then(res => res.json()).then(res => console.log(res)).catch(e => console.log(e))
   }
  useEffect(() => {
    setIsLoading(true)
    fetch(`${process.env.REACT_APP_BASE_URL}/novel/${params.id}`, {
        headers: {
          'authorization': `Bearer ${bearerToken}`,
        },
      }).then(res => res.json())
      .then((res) => {
        setNovelDetails(res.data);
        setIsLoading(false);
        setNovelRating(parseInt(res.data.average_rating));
        const fetchedImages = res.data.images;
        setNovelImages(fetchedImages);
        if (fetchedImages.length > 0) {
          setMainImage(fetchedImages[0]);
        }
        setRatings(res.data.ratings);
        setIsPaid(res.data.is_paid);
      }).catch(e => {
        console.log(e)
      }).finally(() => {
        setIsLoading(false);
      })
  }, []);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {showRequest && (
            <RequestNovel
              setNovelDetails={setNovelDetails}
              HideRequest={handelRequestHide}
              setShowRequest={setShowRequest}
            />
          )}
          {
            !selectedNovel ? 
            <div className="novel">
            <section className="details">
              <div className="details__images">
                <div className="main-container">
                  {mainImage && (
                    <img
                      src={`${process.env.REACT_APP_LARAVEL_BASE_URL}/${mainImage}`}
                      alt={novelDetails.title}
                    />
                  )}
                </div>
                <div className="pagination">
                  {novelImages.slice(0, 4).map((image, index) => (
                    <div
                      className={`card ${mainImage === image ? "active" : ""}`}
                      style={{ objectFit: "cover" }}
                      key={index}
                    >
                      <img
                        src={`${process.env.REACT_APP_LARAVEL_BASE_URL}/${image}`}
                        alt={novelDetails.title}
                        onClick={() => handleImageClick(image)}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className="details__content">
                <h1 className="title">{novelDetails.title}</h1>
                <h3 className="author">{novelDetails.author_name}</h3>
                <div className="rating">
                  <div className="stars">
                    {[...Array(novelRating)].map((_, index) => (
                      <StarIcon key={index} />
                    ))}
                  </div>
                  <div className="count">
                    <p>
                      ({novelDetails.ratings_count} {t("ratings")})
                    </p>
                  </div>
                </div>

                <div className="description">
                  <p>{novelDetails.description}</p>
                </div>
                <button disabled={ isAdding }
                    className="request"
                    onClick={() => {
                      handleAddToNovels(novelDetails);
                    }}
                  >
                    {t("Read Now")}
                  </button>
              </div>
            </section>
            {ratings.length >= 5 && (
              <section className="reviews">
                <div
                  className={`reviews-heading ${
                    currentLang === "ar" ? "ar" : ""
                  }`}
                >
                  <h1>{t("Customer Reviews")}</h1>
                  <h3>
                    {novelDetails.ratings_count
                      ? novelDetails.ratings_count
                      : "0"}{" "}
                    {t("Reviews")}{" "}
                    {novelDetails.average_rating
                      ? `(${novelDetails.average_rating} ${t("Stars")})`
                      : "0"}
                  </h3>
                </div>
                <div className="reviews-body">
                  {ratings.map((rating, key) => (
                    <div className="review" key={key}>
                      <div className="review-header">
                        <UserCircleIcon className="user-icon" />
                        <h3>{rating.username}</h3>
                        <div className="stars">
                          {[
                            ...Array(parseInt(novelDetails.average_rating)),
                          ].map((_, index) => (
                            <StarIcon key={index} />
                          ))}
                        </div>
                      </div>
                      <div className="review-body">{rating.comment}</div>
                    </div>
                  ))}
                </div>
              </section>
                                  )}

                                  <div className="ad-container" >
                                      <AdLinear adSlot="6627496833" />
                                  </div>

          </div>
          : 
                              <NovelsDahboard novel={selectedNovel} />

          }
        </>
      )}
    </>
  );
};

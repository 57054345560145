import React, { useRef, useState, useEffect } from 'react';
import HTMLFlipBook from 'react-pageflip';
import { pdfjs } from 'react-pdf';
import { useAuth } from 'hooks';
import { RateNovel } from '.';
import { useTranslation } from 'react-i18next';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const containerStyles = {
    minHeight: '100vh',
    width: '100vw',
    gap: '1.25rem',
    backgroundColor: '#4a4a4a',
    overflow: 'hidden',
    position: 'relative',
    direction: 'rtl'
};

const Pages = React.forwardRef((props, ref) => {
    return (
        <div className="demoPage" ref={ref}>
            {props.children}
        </div>
    );
});
Pages.displayName = 'Pages';

export function NovelsDahboard({ novel = {} }) {
    const id = novel?.id
    const direction = novel?.direction
    const [ startPage, setStartPage ] = useState(0) 
    const [ images, setImages ] = useState([])
    const {bearerToken } = useAuth();
    const { t } = useTranslation()
    const [currentPage, setCurrentPage] = useState(0);
    const [ isLoading, setIsLoading ] = useState(false)
    const flipBookRef = useRef(null);
    const [showRate, setShowRate] = useState(false);
    const [ manualPageNumber, setManualPageNumber ] = useState(null)
    // const [ renderedValue, setRenderedValue ] = useState(0)
    const inputRef = useRef(null)
    // const [ renderImage, setRenderImage ] = useState([])
    // const [imagesLoaded, setImagesLoaded] = useState(false);
    const handleRateClick = () => {
      setShowRate(true);
    };
    const saveLastPage = (page) => {
        if(isLoading) {
            return
        }
        const url = `${process.env.REACT_APP_BASE_URL}/update-progress`;
        const formData = new FormData();
        formData.append('novel_id', id);
        formData.append('page_number', direction === 'rtl' ? ((novel?.novel_images.length - 1) - page) : page);
        setManualPageNumber(direction === 'rtl' ? ((novel?.novel_images.length - 1) - page) : page)
        const header = {
            'authorization': 'Bearer ' + bearerToken
        }
        const options = {
            method: "POST",
            headers: header,
            body: formData,
        };
        setIsLoading(true)
        fetch(url, options)
            .then(res => res.json())
            .then(res => {
                // console.log(res)
            })
            .catch(e => console.log(e))
            .finally(() => setIsLoading(false))
    }
    function handleDialogClose () {
        setShowRate(false)
    }
    function handleNextPage () {
        if (flipBookRef.current) {
            if(direction === 'rtl') {
                flipBookRef.current?.pageFlip()?.flipPrev()
            } else {
                flipBookRef.current?.pageFlip()?.flipNext()
            }
        }
    }
    function handlePrevPage () {
        if(direction === 'rtl') {
            flipBookRef.current?.pageFlip()?.flipNext()
        } else {
            flipBookRef.current?.pageFlip()?.flipPrev()
        }
    }
    const turnToPage = (e) => {
        e.preventDefault()
        const page = direction === 'rtl' ? (images.length - 1) - Number(manualPageNumber) : Number(manualPageNumber)
        if(page >= images.length - 1 || page < 0) {
            alert(t('Selected Page Out Of Range'));
            return
        }
        if (flipBookRef.current) {
            flipBookRef.current?.pageFlip()?.turnToPage(page);
        }
    };
    useEffect(() => {
        setImages(() => [...novel?.novel_images])
        const page = direction === 'rtl' ? (novel?.novel_images.length - 1) - Number(novel.page_number) : Number(novel.page_number);
        setStartPage(page)
        setCurrentPage(page)
    }, [])
    useEffect(() => {
        if(currentPage === 0) {
            return
        }
        saveLastPage(currentPage)
    }, [ currentPage ])
    return (
        <div style={containerStyles}>
        <div className="mx-auto py-12">
        <div className='page-handler-container gaping-1'>
                <button onClick={ handleNextPage }>{ t('Next') }</button>
                <form onSubmit={ (e) => turnToPage(e) }>
                    <input value={ manualPageNumber } ref={ inputRef } onInput={ e => setManualPageNumber(e.target.value) } className='page-input' type='number' />
                </form>
                <button onClick={ handlePrevPage }>{ t('Prev') }</button>
            </div>
            <div className="w-full flex items-center justify-center my-4">
            <HTMLFlipBook 
                ref={flipBookRef} 
                onFlip={(e) => setCurrentPage(e.data)} 
                width={400} 
                height={570}
                startPage={ startPage }
                rtl={ direction === 'rtl' ? true : false }
            >
                {
                    images.map((item, i) => {
                        return (
                            <div className="demoPage" key={ i }>
                                {
                                    (i <= currentPage + 4 && i >= currentPage - 4) &&
                                    <img loading='lazy' alt={`Page ${i}`} src={ process.env.REACT_APP_LARAVEL_BASE_URL +'/'+ item } />
                                }
                            </div>
                        )
                    })
                }
            </HTMLFlipBook>
            </div>
            <button className='mx-auto block' onClick={ handleRateClick }>{ t('Rate') }</button>
            { showRate && (
                <RateNovel NovelId={ id } HandleHideRate={ handleDialogClose } />
            )}
        </div>
        </div>
    );
}
import Logo from "../../assets/img/logo.svg";
import Email from "../../assets/img/email.svg";
import Phone from "../../assets/img/phone.svg";
import Instagram from "../../assets/img/instagram.svg";
import Facebook from "../../assets/img/facebook.svg";
import SearchIcon from "../../assets/img/search_icon.svg";
import LangIcon from "../../assets/img/language.svg";
import LoginIcon from "../../assets/img/login.svg";
import support from '../../assets/img/support.svg'
import novels from '../../assets/img/novels.svg'
import profile from '../../assets/img/profile.svg'
// import DropdownMenu from "components/Dropdown/Dropdown";
import {ReactComponent as BurgerIcon} from "../../assets/img/burger.svg";
import {ReactComponent as SearchIconResposive} from "../../assets/img/search.svg";
import { Login, RequestNovel } from "components";
import { useAuth } from "hooks";
// import PersonIcon from "../../assets/img/person.svg";
import { useCallback, useEffect, useState, useRef } from "react";
import { t } from "i18next";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import { FaSpinner } from "react-icons/fa";
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
// import axios from "axios";
import { useLocation } from "react-router-dom";
export const Header = () => {
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const dropdownRef = useRef(null);
  const currentLang = i18next.language;
  const { loginShow, setLoginShow, user, bearerToken } = useAuth();
  const [search, setSearch] = useState("");
  const [searchResault, setSearchResault] = useState([]);
  const [email, setEmail] = useState("");
  const [facebook, setFacebook] = useState("");
  const [insta, setInsta] = useState("");
  const [phone, setPhone] = useState("");
  const navigate = useNavigate()
  const [langDrop, setLangDrop] = useState(false);
  const navBarHeight = 8*16;
  const [novelDetails, setNovelDetails] = useState([]);
  const [showRequest, setShowRequest] = useState(false);
  const [showNavResponsive , setShowNavResponsive] = useState(false);
  const [searchBarResponsive , setSearchBarResponsive] = useState(false);
  const [isLoadingSearch , setIsLoadingSearch] = useState(false);
  const [showRequestAnimations, setShowRequestAnimations] = useState(false);

  const fetchData = useCallback(() => {
       const data = localStorage.getItem('novelDetails')
        setNovelDetails(data);
        setShowRequest(true)
  }, []);

  const {i18n} = useTranslation();

  const handleToggleLang = (e)=>{
    e.preventDefault();
    setLangDrop(!langDrop);
    setSearchBarResponsive(false);
    setShowNavResponsive(false);
  }

  const handelRequestHide = () => {
    setShowRequestAnimations(true);
    setTimeout(() => {
      setShowRequest(false);
      setShowRequestAnimations(false);
    }, 300);
  };
  const handleSelectLang = (lang) =>{
    i18n.changeLanguage(lang);
    setLangDrop(false);
    document.querySelector('html').setAttribute('lang', lang);
    if (lang === 'ar') {
      document.body.classList.add('rtl');
    } else {
      document.body.classList.remove('rtl');
    }
  }

  // const handleBlur = () => {
  //   setSearch("");
  // };
  const handleToggleNav = ()=>{
    setShowNavResponsive(!showNavResponsive);
    if(searchBarResponsive){setSearchBarResponsive(false)}
    if(langDrop){setLangDrop(false)}
  }

  const handleToggleSearch = ()=>{
    setSearchBarResponsive(!searchBarResponsive);
    if(showNavResponsive){setShowNavResponsive(false)}
    if(langDrop){setLangDrop(false)}
  }

  const handleSectionNav = () =>{
    if (window.location.pathname !== "/") {
      window.location.href = "/";
    }
    
      handleScroll();
    
  };
  const handleScroll = () => {
    setShowNavResponsive(false);
    // setSearchBarResponsive(false);
    setLangDrop(false);
  };
  const handleNavigator = (val) => {
    if(user) {
      navigate(val);
      setOpen(false);
    } else {
      setLoginShow(true);
    }
  }
  useEffect(() => {
    const handleSerach = () => {
      if (!search) {
        setSearchResault([]);
        return;
      }
      setIsLoadingSearch(true);
      const url = `${process.env.REACT_APP_BASE_URL}/search-by-novels?search=${search}`;
      fetch(url)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log(data)
          if (data.status) {
            setSearchResault(data.data);
          } else {
            setSearchResault([]);
          }
          setIsLoadingSearch(false);
        })
        .catch(()=>{
          setIsLoadingSearch(false);
        });
    };
    handleSerach();
  }, [search]);

  useEffect(() => {
    const fetchContent = () => {
      const url = `${process.env.REACT_APP_BASE_URL}/about-us-info`;
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          setEmail(data.data.email);
          setFacebook(data.data.facebook);
          setInsta(data.data.instagram);
          setPhone(data.data.phone_number);
        }).catch(e => {
          console.log(e)
        });
    };
    fetchContent();
  }, []);
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <header className="header">
         {showRequest && (
            <RequestNovel
              HideRequest={handelRequestHide}
              Animations={showRequestAnimations}
              setShowRequest={setShowRequest}
            />
          )}
      {loginShow && <Login />}
      <div className={`lang-change ${langDrop ? 'active' : ''}`}>
        <ul>
          <li onClick={()=>{handleSelectLang('ar')}}>
            {t('Arabic')}
          </li>
          <li onClick={()=>{handleSelectLang('en')}}>
            {t('English')}
          </li>
        </ul>
      </div>
      <div className="top-nav">
        <nav className="nav container" >
          <a href="/" style={{display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center'}}>
            <img src={Logo} alt="FA Novels" className="nav__logo-img" style={{marginTop:'25px'}} />
          </a>
          <div className="nav__menu">
            <div className="list">
              {email && 
                <a href={`mailto:${email}`} className="item">
                  <img src={Email} alt="Email" />
                </a>
              }

              {phone && 
                <a href={`tel:${phone}`} className="item">
                  <img src={Phone} alt="Phone" />
                </a>
              }
              
              {insta &&
                <a href={insta} className="item">
                  <img src={Instagram} alt="Instagram" />
                </a>
              }

              {facebook && 
                <a href={facebook} className="item">
                  <img src={Facebook} alt="Facebook" />
                </a>
              }
            </div>
            <div className={`search ${searchBarResponsive ? 'active' : ''}`} style={i18n.language === 'ar' ? { direction: 'rtl' } : {}} >
              
              {isLoadingSearch ? <FaSpinner className={`spinner icon ${i18n.language === 'ar' ? 'ar' : ''}`} /> : <img src={SearchIcon} className={`icon ${i18n.language === 'ar' ? 'ar' : ''}`} alt="search" />}
              <input
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                // onBlur={handleBlur}
                type="text"
                placeholder={t('SEARCH FOR TITLES')}
              />
              <div className="resaults">
                {searchResault.map((resault) => (
                  <div className="resault"  
                  onClick={()=>{navigate(`/novel/${resault.id}`);setSearch('')}}>
                    <img
                      src={`${process.env.REACT_APP_LARAVEL_BASE_URL}/${resault.image}`}
                      alt=""
                    />
                    <div className="informations">
                      <h3>{resault.title}</h3>
                      <p>
                        {resault.price_after_discount ? (
                          <>
                            <span className="line-throw">{resault.price}</span>{" "}
                            {resault.price_after_discount + " " + t("SAR")}
                          </>
                        ) : (
                          resault.price + " " + t("SAR")
                        )}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="second-list">
            {                 
                    <a
                      href="/"
                      className="item auth"
                      onClick={(e) => {
                        if(!user) {
                          e.preventDefault();
                          setLoginShow(true);
                        } else {
                          localStorage.clear();
                          alert('Logged out successfully')
                        }
                      }}
                    >
                    <img src={LoginIcon} alt="Email" />
                    </a> }
              <a href="/lang" onClick={(e)=>{handleToggleLang(e)}} className="item lang-drop">
                <img src={LangIcon} alt={t("language")} />
              </a>
                  {
                    user &&                   <div className="dropdown-container show-small" ref={dropdownRef}>
                    {/* Button Trigger */}
                    <button className="menu-button" onClick={() => setOpen(!open)}>
                      ⋮
                    </button>
              
                    {/* Dropdown Menu */}
                    {open && (
                      <div className="dropdown-menu">
                        <div onClick={() => handleNavigator('/profile')} className="dropdown-item">
                          { t('Profile') }
                        </div>
                        <div onClick={() => handleNavigator('/support')} className="dropdown-item">
                        { t('Support') }
                        </div>
                        <div onClick={() => handleNavigator('/novels')} className="dropdown-item">
                        { t('Novels') }
                        </div>
                      </div>
                    )}
                  </div>
                  }

                    {
                      user && 
                      <div className="flex items-center gap-4 flex-big">
                        <a onClick={()=>{navigate('/support')}} className="item auth">
                          <img className="w-4 h-4" src={ support } alt="support" style={{cursor:'pointer'}}/>
                        </a>
                        <a onClick={()=>{navigate('/novels')}} className="item auth">
                          <img className="w-4 h-4" src={ novels } alt="novels" style={{cursor:'pointer'}}/>
                        </a>
                        <a onClick={()=>{navigate('/profile')}} className="item auth">
                          <img className="w-4 h-4" src={ profile } alt="profile" style={{cursor:'pointer'}}/>
                        </a>
                      </div>
                    }
              
            </div>
          </div>
          <SearchIconResposive onClick={handleToggleSearch} className="search-mobile-icon"/>
          <BurgerIcon className={`menu-icon ${showNavResponsive ? 'active' : ''}`} onClick={handleToggleNav}/>                
        </nav>
      </div>
      {
        pathname === '/' && 
        <div className={`bottom-nav ${showNavResponsive ? 'active' : ''}`}>
        <nav className="nav container">
          <ul className="list">
            <li className="item">
              <Link onClick={handleSectionNav} offset={-navBarHeight} to="home-section" smooth={true} duration={100} spy>
                {t("Home")}
              </Link>
            </li>
            <li className="item">
            <Link onClick={handleSectionNav} offset={-navBarHeight} to="categories-section" smooth={true} duration={100} spy>
                {t("Categories")}
              </Link>
            </li>
            <li className="item">
            <Link onClick={handleSectionNav} offset={-navBarHeight} to="about-section" smooth={true} duration={100} spy>
                {t("About Us")}
              </Link>
            </li>
            <li className="item">
            <Link onClick={handleSectionNav} offset={-navBarHeight} to="most-wanted-section" smooth={true} duration={100} spy>
                {t("Most Wanted")}
              </Link>
            </li>
            <li className="item">
              <Link onClick={handleSectionNav} offset={-navBarHeight} to="contact-section" smooth={true} duration={100} spy>
                {t("GET IN TOUCH")}
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      }
    </header>
  );
};
